import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Tutorial Hell`}</h1>
    <p>{`Tutorials are a great way for you to learn new things and concepts while building something interesting at the same time. You can find a lot of tutorials and if you wish, you could build a portfolio by just following tutorials and recreating things that the tutorial is showing you.`}</p>
    <p>{`But not everything is great. With tutorials, you can easily get into the loop of jumping from one tutorial to the other. You usually get a feeling of accomplishment when completing a tutorial and it's pretty common to find people that keep doing tutorials over and over again.`}</p>
    <h2>{`Welcome to Hell`}</h2>
    <p>{`So what is tutorial hell?`}</p>
    <p>{`It's a place where you keep doing the same thing over and over again without any guidance on how to break free from this cycle.`}</p>
    <p>{`As I said before, completing tutorials make you feel great and it's quite addictive to go and try to complete as many as possible. But when you try to do something similar without the guidance of the tutorial you will probably get stuck and won't be able to keep going.`}</p>
    <p>{`As a result, you decide to go out and follow another tutorial.`}</p>
    <p>{`Does this sound familiar?`}</p>
    <h2>{`Getting into tutorial hell`}</h2>
    <p>{`After I completed the course `}<em parentName="p">{`introduction to CS with python,`}</em>{` I was eager to start learning new things, so I decided to follow a few tutorials that I found online. `}</p>
    <p>{`I really enjoyed learning by watching videos and following along, so I spent a few months doing just that - watching videos, rewriting the code into my editor and trying to get the same results.`}</p>
    <p>{`The problem was when I wanted to change the code to do something else, or to add a new feature. Most of the times the changes that I added either broke the whole thing or didn't do as I expected.`}</p>
    <p>{`When things didn't work as expected I just shrug it off and told myself that perhaps I just needed to learn more. And off I went into another tutorial.`}</p>
    <h2>{`The problem with tutorial hell`}</h2>
    <p>{`The issue with being stuck in the tutorial hell is that you get fake confidence in your coding skills. By completing tutorials you feel that your skills are improving and as long as you keep doing tutorials you will become a great coder.`}</p>
    <p>{`Even though you are probably learning a bit, you will never really become great by just following tutorials. `}</p>
    <p>{`When you follow a tutorial it's as if you have someone holding your hand when you are trying to learn how to walk. Sure walking seems fine, but when that someone releases your hand you will most likely stumble and fall.`}</p>
    <p>{`I'm not going to lie, being stuck does suck. It makes you feel powerless, dumb and makes you start questioning if coding is indeed something you should keep learning. `}</p>
    <p><strong parentName="p">{`Being stuck is important!`}</strong>{` `}</p>
    <p>{`When you are stuck, your brain is trying different approaches to solve a problem. This is the time when you learn the most and something that you shouldn't try to avoid.`}</p>
    <p>{`Getting stuck will make you learn things and will improve your problem-solving skills which are a very important thing to have when you decide to become a developer.`}</p>
    <h2>{`Breaking free from hell`}</h2>
    <p>{`You don't need to be forever stuck into this cycle of starting a new tutorial after completing one.`}</p>
    <blockquote>
      <p parentName="blockquote">{`The definition of insanity is doing the same thing over and over again and expecting a different result.`}</p>
    </blockquote>
    <p>{`There is nothing wrong with using tutorials to learn new things. You just need to stop that impulse of starting a new tutorial as soon as you finish the one you were working on.`}</p>
    <p>{`When you complete a tutorial, try to recreate the same code using only your memory. You will probably be stuck in a few things when you do rewatch/reread that bit of the tutorial to get unstuck.`}</p>
    <p>{`By doing this, you will start understanding how the code falls into place and what sort of things you should practice more.  Probably the reason why you got stuck while trying to recreate the tutorial was because you didn't understand that concept properly.`}</p>
    <p>{`After you were able to recreate the same code as the tutorial, why not expand it? Add a new feature or do some changes to things, this will be hard at first but you will quickly learn how to work with a codebase and figure out what sort of things you might need to refactor and delete.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      